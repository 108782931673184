:root{
    --main: rgb(11, 102, 35);
    --light: rgb(133, 179, 145);
    --credits: 2px;
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Lobster Two';
    font-weight: 700;
    src: local('Lobster Two'), url(./fonts/LobsterTwo-Bold.ttf) format('truetype');
}

/* selection color */
::-moz-selection { /* Firefox */
    color: var(--main);
    background: var(--light);
}
  
::selection {
    color: var(--main);
    background: var(--light);
}

/* input number without arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.slideOverflow {
    overflow: clip !important;
}

@media (hover: hover){
    #tape:hover #tapeStyleHover {
        visibility: visible;
    }
    
    #tape:hover #tapeStyleCheckHover {
        visibility: hidden;
    }
    
    #favorite:hover #heart {
        display: none;
    }
    
    #favorite:hover #heartHover {
        display: flex;
    }
    
    #wakeLock:hover #eye {
        display: none;
    }
    
    #wakeLock:hover #eyeHover {
        display: flex;
    }    
}

@media (hover: none){
    #tape:active #tapeStyle {
        visibility: visible;
    }
    
    #tape:active #tapeStyleCheck {
        visibility: hidden;
    }
    
    #favorite:active #heart {
        display: none;
    }
    
    #favorite:active #heartHover {
        display: flex;
    }
    
    #wakeLock:active #eye {
        display: none;
    }
    
    #wakeLock:active #eyeHover {
        display: flex;
    }    
}


.Toastify__toast-container {
    padding: 0 !important;
    max-width: calc(100% - 2 * 24px);
}

.Toastify__toast-container--bottom-left {
    left: 24px !important;
    bottom: calc(24px - 6px) !important;
}

.Toastify__toast {
    border-radius: 0 !important;
    margin-bottom: 6px !important;
    min-height: 50px !important;
}

#aliment:hover #wikipedia {
    display: flex !important;
}

#slider:hover #sliderNext {
    visibility: visible !important;
}
#slider:hover #sliderBack {
    visibility: visible !important;
}
#slider:hover #sliderCount {
    visibility: visible !important;
}
